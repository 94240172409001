import { Injectable, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { CreateProductDialogComponent } from '@app/shared/component/create-product-dialog/create-product-dialog.component';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { ModalBaseComponent } from '@app/shared/component/dialog/modal-base/modal-base.component';
import { SidebarMultiselectLayoutService } from '@app/shared/component/sidebar-multiselect-layout/sidebar-multiselect-layout.service';
import { EntityWithId, Row } from '@app/shared/component/table/table.types';
import { TableSidebarLayoutService } from '@app/shared/component/table-sidenav-layout/service/table-sidebar-layout.service';
import { ExportReportService } from '@app/shared/service/export-report.service';
import { ImagesService } from '@app/shared/service/images.service';
import { Workshop } from '@app/typings/workshop';
import { catalogSectionToAutocompleteOption } from '@app/utils/catalog-section';
import {
  CatalogRoute,
  exportUrlByReportType,
  LITER_OR_KILOGRAM,
  MAX_CHARACTERS,
  MAX_FRACTIONAL,
  MAX_INTEGER,
  MILLILITER_OR_GRAM,
  PRODUCT_LIST_ROUTES,
  PRODUCT_TYPE,
  UNIT_TYPE,
  WEIGHT_UNIT_TYPE,
} from '@constants';
import { UtilsService, ValidationErrorsService } from '@core/service';
import { FormConfirmSaveService } from '@core/service/form-confirm-save/form-confirm-save.service';
import { environment } from '@env/environment';
import { SessionStorage } from '@services/api';
import { NotifyService, SidenavService } from '@services/shared';
import {
  BarcodeInput,
  Catalog,
  CatalogSection,
  CatalogTechCardItemInput,
  InfoForm,
  MutationBatchResult,
  MutationDuplicateProductArgs,
  MutationMoveProductArgs,
  MutationResult,
  MutationUpdateProductV3Args,
  PageRequestInput,
  Product,
  ProductCreateInput,
  ProductDialogForm,
  ProductFilterInput,
  ProductPage,
  ProductPatchUpdateInput,
  ProductRowData,
  ProductType,
  ProductWithArrivals,
  QueryResult,
  QueryStockUnitsArgs,
  StockUnitInput,
  StockUnitPatchUpdateInput,
  TechCard,
  TechCardForm,
  TechCardItem,
  TechCardItemAddInput,
  TechCardItemForm,
  TechCardPatchUpdateInput,
  UnitType,
} from '@typings';
import { MULTIPLIER, setLossesShort, setPrimePrice, validateNumber } from '@utils';

import { CatalogStorage } from './catalog.storage';
import { ProductStorage } from './product.storage';
export type Tab = {
  id: string;
  label: string;
  disabled: boolean;
};
@Injectable({
  providedIn: 'root',
})
export class ProductService {
  form: FormGroup<InfoForm>;
  controls: InfoForm;
  productArrivalsExist: boolean | null = false;
  catalog: Catalog;
  product: null | undefined | ProductWithArrivals;
  productType: ProductType = PRODUCT_TYPE.PRODUCT;
  modalRef: ModalRef<unknown>;
  multiplier: number = 1000;
  percent: number = 100;
  productDialogForm: FormGroup<ProductDialogForm>;
  productDialogRows: Row<EntityWithId, ProductRowData>[];
  productDialogIndex: number;
  isEditing$ = new BehaviorSubject(false);
  isSubmitDisabled$ = new BehaviorSubject(false);
  updateProductInTable = new Subject<Product>();
  removeProductFromTable = new Subject<string>();
  openModifierGroup = false;
  sidenavMode = false;
  tabs: Tab[] = [];
  currentTab = new BehaviorSubject<number>(0);

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sidenavService: SidenavService,
    private catalogStorage: CatalogStorage,
    private productStorage: ProductStorage,
    private formConfirmSaveService: FormConfirmSaveService,
    private modalService: ModalService,
    private imagesService: ImagesService,
    private validationErrorsService: ValidationErrorsService,
    private utilsService: UtilsService,
    private notifyService: NotifyService,
    private exportReportService: ExportReportService,
    private sessionStorage: SessionStorage,
    private tableSidebarService: TableSidebarLayoutService,
    private multiSelectService: SidebarMultiselectLayoutService,
  ) {}

  initState(product: null | ProductWithArrivals | undefined, tab?: number): void {
    this.catalog = this.catalogStorage.getCatalog();

    if (!product && this.tableSidebarService.isRedirected()) {
      return;
    }
    const currentTab = tab && tab > 0 ? tab : 0;
    this.setCurrentTab(currentTab);

    if (product) {
      this.productStorage.setProduct(product);
    }
    if (product !== undefined) {
      this.product = product;
    }
  }

  setProductType(productType: ProductType): void {
    this.productType = productType;
  }

  initForm(shouldRegister: boolean = true, resetTechCard = true): void {
    const prevStockUnit = this.controls?.techCard;

    this.form = this.fb.group<InfoForm>({
      image: this.fb.control(null),
      name: this.fb.nonNullable.control('', [Validators.required, Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME)]),
      description: this.fb.nonNullable.control('', [Validators.maxLength(MAX_CHARACTERS.SHORT_DESCRIPTION)]),
      type: this.fb.nonNullable.control(this.productType || PRODUCT_TYPE.PRODUCT, [Validators.required]),
      section: this.fb.control(null, [Validators.required]),
      workshop: this.fb.control(null),
      weighable: this.fb.nonNullable.control(false),
      unit: this.fb.nonNullable.control(UNIT_TYPE.PIECE, [Validators.required]),
      quantity: this.fb.nonNullable.control('1', [Validators.required, Validators.min(1)]),
      leavings: this.fb.nonNullable.control('0', [Validators.required]),
      positionTypeCode: this.fb.control(null, [Validators.required]),
      sno: this.fb.control(null, [Validators.required]),
      vat: this.fb.control(null, [Validators.required]),
      barcodes: this.fb.nonNullable.array([this.fb.nonNullable.group({ id: '', code: '' })]),
      changeWorkshop: this.fb.nonNullable.control(false),
      proteins: this.fb.nonNullable.control(''),
      fats: this.fb.nonNullable.control(''),
      carbons: this.fb.nonNullable.control(''),
      calories: this.fb.nonNullable.control(''),
      primePrice: this.fb.nonNullable.control('0'),
      surcharge: this.fb.nonNullable.control('0'),
      salePrice: this.fb.nonNullable.control('0'),
      freePrice: this.fb.nonNullable.control(false),
      weight: this.fb.nonNullable.control(''),
      weightUnit: this.fb.nonNullable.control(WEIGHT_UNIT_TYPE.GRAM),
      weightedAveragePrimePrice: this.fb.nonNullable.control(''),
      lastArrivalPrimePrice: this.fb.nonNullable.control(''),
    });

    this.controls = this.form.controls;
    this.controls.barcodes.removeAt(0);
    this.productArrivalsExist = false;

    if (resetTechCard || !prevStockUnit) {
      this.addTechCard();
      this.getSumNetto();
    } else {
      this.form.registerControl('techCard', prevStockUnit);
    }

    if (this.catalog) {
      const { positionType, snoValue, vatValue } = this.catalog;

      if (positionType && positionType.code) {
        this.controls.positionTypeCode.setValue(positionType.code);
      }

      if (snoValue && snoValue.sno) {
        this.controls.sno.setValue(snoValue.sno);
      }

      if (vatValue && vatValue.vat) {
        this.controls.vat.setValue(vatValue.vat);
      }
    }
    if (this.product) {
      const { name, type, section, workshop, weight, weightUnitType, stockUnits } = this.product;
      const {
        images,
        weighable,
        nutritionFact,
        unit,
        quantity,
        positionType,
        snoValue,
        vatValue,
        barcodes,
        primePrice,
        salePrice,
        techCard,
        freePrice,
        description,
        weightedAveragePrimePrice,
        lastArrivalPrimePrice,
      } = stockUnits[0];

      if (images.length) {
        this.controls.image.setValue(images[0]);
      }

      this.controls.name.setValue(name);
      this.controls.description.setValue(description || '');
      if (weight) {
        this.controls.weight.setValue(String(weight));
      }
      if (weightUnitType) {
        this.controls.weightUnit.setValue(weightUnitType);
      }

      if (workshop) {
        const option: AutocompleteOption<Workshop> = {
          id: workshop.id,
          label: workshop.name,
          type: 'item',
          data: workshop,
        };
        this.controls.workshop.setValue(option);
      }

      if (type) {
        this.controls.type.setValue(type);
      }

      this.controls.freePrice.setValue(freePrice);
      this.controls.lastArrivalPrimePrice.setValue(
        lastArrivalPrimePrice?.amountValue ? this.formatNumber(lastArrivalPrimePrice?.amountValue) : '',
      );
      this.controls.weightedAveragePrimePrice.setValue(
        weightedAveragePrimePrice?.amountValue ? this.formatNumber(weightedAveragePrimePrice?.amountValue) : '',
      );

      if (section) {
        this.controls.section.setValue(catalogSectionToAutocompleteOption(section) as AutocompleteOption<CatalogSection>);
      }

      if (nutritionFact) {
        const { calories, carbohydrates, fat, protein } = nutritionFact;
        if (calories) {
          this.controls.calories.setValue(String(calories));
        }

        if (carbohydrates) {
          this.controls.carbons.setValue(String(carbohydrates));
        }

        if (fat) {
          this.controls.fats.setValue(String(fat));
        }

        if (protein) {
          this.controls.proteins.setValue(String(protein));
        }
      }

      this.controls.weighable.setValue(!weighable);
      this.controls.unit.setValue(unit);
      this.controls.quantity.setValue(quantity);
      if (this.product?.productArrivalsExist) {
        this.controls.unit.disable();
        this.productArrivalsExist = this.product.productArrivalsExist;
      }

      if (positionType && positionType.code) {
        this.controls.positionTypeCode.setValue(positionType.code);
      }

      if (snoValue && snoValue.sno) {
        this.controls.sno.setValue(snoValue.sno);
      }

      if (vatValue && vatValue.vat) {
        this.controls.vat.setValue(vatValue.vat);
      }

      if (barcodes && barcodes.length) {
        barcodes.forEach((barcode) => this.controls.barcodes.push(this.fb.nonNullable.group(barcode)));
      }

      if (primePrice) {
        this.controls.primePrice.setValue(primePrice.amountValue);
        this.controls.salePrice.setValue(salePrice.amountValue);

        const surcharge = Number(
          100 * ((Number(salePrice.amountValue) - Number(primePrice.amountValue)) / Number(primePrice.amountValue)),
        ).toFixed(2);

        this.controls.surcharge.setValue(validateNumber(String(surcharge), MAX_INTEGER.SURCHARGE, MAX_FRACTIONAL.SURCHARGE));
      }

      if (techCard && (resetTechCard || !prevStockUnit)) {
        this.addTechCard(techCard);
        this.getSumNetto();
      }
    }

    this.isSubmitDisabled$.next(false);
    this.isEditing$.next(!!this.product);

    if (shouldRegister) {
      this.formConfirmSaveService.setForm(this.form, undefined, () => this.resetForm());
    }
  }

  resetForm() {
    if (!this.tableSidebarService.isRedirected()) {
      this.initState(undefined);
      this.initForm(false);
    }
  }

  resetPositionTypeCode(): void {
    const section = this.controls.section.value;
    const productPositionType = this.product?.stockUnits[0].positionType;

    if (section) {
      this.controls.positionTypeCode.setValue(section.data?.positionType?.code);

      if (productPositionType) {
        this.controls.positionTypeCode.setValue(productPositionType.code);
      }
    }
  }

  resetSno(): void {
    const section = this.controls.section.value;
    const productSno = this.product?.stockUnits[0].snoValue;

    if (section) {
      this.controls.sno.setValue(section.data?.snoValue?.sno);

      if (productSno) {
        this.controls.sno.setValue(productSno.sno);
      }
    }
  }

  resetVat(): void {
    const section = this.controls.section.value;
    const productVat = this.product?.stockUnits[0].vatValue;

    if (section) {
      this.controls.vat.setValue(section.data?.vatValue?.vat);

      if (productVat) {
        this.controls.vat.setValue(productVat.vat);
      }
    }
  }

  resetSnoAndVat(): void {
    this.resetSno();
    setTimeout(() => this.resetVat());
  }

  addBarcode(barcodeInput: BarcodeInput): void {
    this.controls.barcodes.push(this.fb.nonNullable.group({ ...barcodeInput, id: '' }));
    this.hideModal();
  }

  removeBarcode(index: number): void {
    this.controls.barcodes.removeAt(index);
  }

  toBarcodeInputs(): BarcodeInput[] {
    return this.controls.barcodes.controls.map((control) => {
      const { id, code } = control.controls;
      const barcodeInput: BarcodeInput = { code: code.value };

      if (id.value) {
        barcodeInput.id = id.value;
      }

      return barcodeInput;
    });
  }

  priceChange(): void {
    const primePrice = Number(this.controls.primePrice.value);
    const salePrice = Number(this.controls.salePrice.value);
    const surcharge = Number(100 * ((salePrice - primePrice) / primePrice)).toFixed(2);

    this.controls.surcharge.setValue(validateNumber(String(surcharge), MAX_INTEGER.SURCHARGE, MAX_FRACTIONAL.SURCHARGE));
  }

  addTechCard(techCard?: TechCard): void {
    this.form.registerControl(
      'techCard',
      this.fb.nonNullable.group<TechCardForm>({
        id: this.fb.control(null),
        items: this.fb.nonNullable.array([this.addTechCardItem()]),
        processOfCooking: this.fb.control('', [Validators.maxLength(MAX_CHARACTERS.DESCRIPTION)]),
      }),
    );

    if (techCard) {
      const { id, techCardItems, processOfCooking } = techCard;

      if (techCardItems && techCardItems.length) {
        const techCardControl = this.form.controls.techCard;
        if (techCardControl) {
          techCardControl.patchValue({
            id,
            processOfCooking,
          });
          techCardControl.controls.items.clear();
          techCardItems.forEach((item) => techCardControl.controls.items.push(this.addTechCardItem(item)));
          techCardControl.controls.items.push(this.addTechCardItem());
        }
      }
    }
  }

  formatNumber(num: string): string {
    return String(Math.floor(Number(num) * 100) / 100).replace(/\.0+$/, '');
  }

  addTechCardItem(techCardItem?: TechCardItem): FormGroup<TechCardItemForm> {
    const group: FormGroup<TechCardItemForm> = this.fb.nonNullable.group<TechCardItemForm>({
      // MAX_INTEGER.BRUTTO = 10 and MAX_FRACTIONAL.BRUTTO = 3 min value for brutto is 0.001
      brutto: this.fb.nonNullable.control('0'),
      bruttoUnit: this.fb.nonNullable.control({ value: null, disabled: true }),
      netto: this.fb.nonNullable.control('0'),
      stockUnit: this.fb.control(null),
      techCardItemId: this.fb.control(null),
      primePrice: this.fb.nonNullable.control({ value: '0', disabled: true }),
      quantity: this.fb.control(null),
      prevBruttoUnit: this.fb.control(null),
      losses: this.fb.nonNullable.control('0'),
    });

    if (techCardItem) {
      const { id, netto, stockUnit } = techCardItem;

      if (stockUnit) {
        const baseBruttoUnit = techCardItem.stockUnit?.unit;
        const bruttoUnit = techCardItem.bruttoUnitType;
        const basePrimePrice = Number(
          techCardItem.stockUnit?.weightedAveragePrimePrice?.amountValue || techCardItem.stockUnit?.primePrice?.amountValue,
        );
        const baseQuantity = Number(techCardItem.stockUnit?.quantity);
        let brutto = techCardItem.brutto;
        let quantity = Number(techCardItem.stockUnit?.quantity);

        if (bruttoUnit && brutto && quantity) {
          if (bruttoUnit !== baseBruttoUnit) {
            if (LITER_OR_KILOGRAM(bruttoUnit)) {
              brutto = brutto / MULTIPLIER;
              quantity = quantity / MULTIPLIER;
            }

            if (MILLILITER_OR_GRAM(bruttoUnit)) {
              brutto = brutto * MULTIPLIER;
              quantity = quantity * MULTIPLIER;
            }

            if (bruttoUnit === UNIT_TYPE.PIECE) {
              brutto = brutto / quantity;
              quantity = 1;
            }
          }
        }

        group.setValue({
          brutto: String(brutto),
          bruttoUnit,
          netto: String(netto),
          stockUnit: { type: 'item', label: stockUnit.name, id: stockUnit.id, data: stockUnit },
          techCardItemId: id,

          primePrice: String(setPrimePrice(basePrimePrice, quantity, brutto!)),
          quantity: String(quantity),
          prevBruttoUnit: bruttoUnit,
          losses: String(setLossesShort(brutto!, netto!, bruttoUnit!, baseBruttoUnit!, baseQuantity)),
        });

        if (bruttoUnit === UNIT_TYPE.PIECE) {
          group.controls.bruttoUnit.disable();
        } else {
          group.controls.bruttoUnit.enable();
        }
      }
    } else {
      // to make form valid when ingredient is null
      group.controls.brutto.disable();
    }

    return group;
  }

  removeTechCardItem(index: number): void {
    this.controls?.techCard?.controls.items.removeAt(index);
  }

  submitForm(): void {
    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);

      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });

      return;
    }

    this.disableForm(false);

    this.product ? this.updateProduct() : this.createProduct();
  }

  disableForm(emitEvent: boolean = true): void {
    this.form.disable({ emitEvent });
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable();

    if (this.controls.techCard) {
      this.controls.techCard.controls.items.controls.forEach((c) => {
        if (!Boolean(c.controls.stockUnit.value) || c.controls.stockUnit.value?.data?.unit === UNIT_TYPE.PIECE) {
          c.controls.bruttoUnit.disable();
        }
      });
    }

    this.isSubmitDisabled$.next(false);
  }

  async createProduct(): Promise<void> {
    const product = this.createProductCreateInput();
    const image = this.controls.image.value;

    if (image && 'extension' in image) {
      product.stockUnits[0].imageIds = [await this.imagesService.uploadImages([image])];
    }

    this.productStorage.createProduct({ product }).subscribe(
      () => this.toList(product),
      () => this.enableForm(),
    );
  }

  createProductCreateInput(): ProductCreateInput {
    const {
      name,
      type,
      section,
      proteins,
      fats,
      calories,
      carbons,
      workshop,
      changeWorkshop,
      weighable,
      unit,
      quantity,
      positionTypeCode,
      sno,
      vat,
      techCard,
      primePrice,
      salePrice,
      freePrice,
      weight,
      weightUnit,
      description,
    } = this.controls;
    const stockUnitInput: StockUnitInput = {
      id: this.product ? this.product.stockUnits[0]?.id : undefined,
      name: name.value,
      description: description.value,
      weighable: !weighable.value,
      unit: unit.value || 'NONE',
      quantity: quantity.value,
      barcodes: this.toBarcodeInputs(),
      primePrice: { amountValue: primePrice.value || '0', currencyUnit: this.sessionStorage.getCurrencyUnit() },
      salePrice: { amountValue: salePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
      freePrice: freePrice.value,
    };
    const productCreateInput: ProductCreateInput = {
      catalogId: this.catalog.id,
      sectionId: this.controls.section.value!.id,
      name: name.value,
      type: type.value,
      stockUnits: [stockUnitInput],
    };

    if (unit.value === UNIT_TYPE.PIECE) {
      if (weight.value) {
        productCreateInput.weight = Number(weight.value);
      }
      productCreateInput.weightUnitType = weightUnit.value;
    }

    stockUnitInput.nutritionFact = { calories: 0, protein: 0, fat: 0, carbohydrates: 0 };

    if (stockUnitInput.nutritionFact) {
      stockUnitInput.nutritionFact.protein = Number(proteins.value) || 0;
      stockUnitInput.nutritionFact.fat = Number(fats.value) || 0;
      stockUnitInput.nutritionFact.calories = Number(calories.value) || 0;
      stockUnitInput.nutritionFact.carbohydrates = Number(carbons.value) || 0;
    }

    if (changeWorkshop.value) {
      productCreateInput.workshopId = workshop.value ? workshop.value.id : null;
    }

    const parentSection = section.value;

    if (parentSection) {
      if (parentSection.data?.positionType?.code !== positionTypeCode.value) {
        productCreateInput.positionTypeCode = positionTypeCode.value;
      }

      if (parentSection.data?.snoValue?.sno !== sno.value || parentSection.data?.vatValue?.vat !== vat.value) {
        productCreateInput.taxProfile = {
          sno: sno.value,
          vat: vat.value,
        };
      }
    }

    if (techCard) {
      const { id, items, processOfCooking } = techCard.controls;

      if (items.controls.length) {
        const catalogTechCardItemsInput: CatalogTechCardItemInput[] = items.controls
          .filter((control) => !!control.controls.stockUnit.value)
          .map((control) => {
            const itemControls = control.controls;
            const { techCardItemId, stockUnit, netto } = itemControls;
            const bruttoUnit = itemControls.bruttoUnit.value;
            const baseBruttoUnit = itemControls.stockUnit.value?.data?.unit;
            const quantity = Number(itemControls.stockUnit.value?.data?.quantity);
            let brutto = Number(itemControls.brutto.value);

            if (baseBruttoUnit && bruttoUnit) {
              if (bruttoUnit !== baseBruttoUnit) {
                if (LITER_OR_KILOGRAM(bruttoUnit)) {
                  brutto = brutto * MULTIPLIER;
                }

                if (MILLILITER_OR_GRAM(bruttoUnit)) {
                  brutto = brutto / MULTIPLIER;
                }

                if (bruttoUnit === UNIT_TYPE.PIECE) {
                  if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
                    brutto = brutto * quantity;
                  }

                  if (MILLILITER_OR_GRAM(baseBruttoUnit)) {
                    brutto = brutto * quantity;
                  }
                }
              }
            }

            const catalogTechCardItemInput: CatalogTechCardItemInput = {
              techCardItemId: techCardItemId ? techCardItemId.value : undefined,
              stockUnitId: stockUnit.value!.id,
              bruttoUnit: bruttoUnit as UnitType,
              brutto: String(brutto),
              netto: netto.value,
            };

            return catalogTechCardItemInput;
          });

        stockUnitInput.techCard = {
          id: id ? id.value : undefined,
          items: catalogTechCardItemsInput,
          processOfCooking: processOfCooking.value,
        };
      }
    }

    return productCreateInput;
  }

  async updateProduct(): Promise<void> {
    if (!this.product) {
      throw new Error('Missing product');
    }

    const product = this.createProductPatchUpdateInput();
    const productImages = this.product.stockUnits[0].images;
    const image = this.controls.image;

    if (image && product.stockUnit) {
      const imageValue = image.value;

      if (!imageValue && productImages.length) {
        product.stockUnit.removeImageIds = [productImages[0].id] || [];
      }

      if (imageValue && 'body' in imageValue) {
        product.stockUnit.addImageIds = [await this.imagesService.uploadImages([image.value])];

        if (productImages.length) {
          product.stockUnit.removeImageIds = [productImages[0].id] || [];
        }
      }
    }

    this.productStorage.updateProduct({ product }).subscribe(
      (res) => {
        if (!this.sidenavMode) {
          this.toList(product);
        } else {
          this.tableSidebarService.closeTableSidenav();
          this.updateProductInTable.next(res?.data?.updateProductV3?.output as Product);
        }
        this.enableForm();
      },
      () => {
        this.enableForm();
      },
    );
  }

  createProductPatchUpdateInput(): ProductPatchUpdateInput {
    if (!this.product) {
      throw new Error('Missing product');
    }

    const {
      name,
      type,
      section,
      workshop,
      changeWorkshop,
      proteins,
      carbons,
      calories,
      fats,
      weighable,
      unit,
      quantity,
      positionTypeCode,
      sno,
      vat,
      techCard,
      primePrice,
      salePrice,
      freePrice,
      weight,
      weightUnit,
      description,
    } = this.controls;

    const barcodes: string[] = this.controls.barcodes.controls.map((control) => control.value.code!) || [];
    const prevBarcodes: string[] = this.product.stockUnits[0]?.barcodes?.map((barcode) => barcode.code) || [];
    const diffBarcodes = this.utilsService.getListsDiff(prevBarcodes, barcodes);
    const barcodeIds: string[] = this.controls.barcodes.controls.map((control) => control.value.id!) || [];
    const prevBarcodeIds: string[] = this.product.stockUnits[0]?.barcodes?.map((barcode) => barcode.id) || [];
    const diffBarcodeIds = this.utilsService.getListsDiff(prevBarcodeIds, barcodeIds);

    const stockUnitPatchUpdateInput: StockUnitPatchUpdateInput = {
      name: name.value,
      description: description.value,
      weighable: !weighable.value,
      unit: unit.value,
      quantity: quantity.value,
      addBarcodes: diffBarcodes.added,
      removeBarcodeIds: diffBarcodeIds.removed,
      primePrice: { amountValue: primePrice.value || '0', currencyUnit: this.sessionStorage.getCurrencyUnit() },
      salePrice: { amountValue: salePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
      positionTypeCode: positionTypeCode.value,
      freePrice: freePrice.value,
    };

    const parentSection = section.value;
    if (parentSection) {
      if (
        (parentSection.data as CatalogSection).snoValue?.sno !== sno.value ||
        (parentSection.data as CatalogSection)?.vatValue?.vat !== vat.value
      ) {
        const snoValue = sno.value;
        const vatValue = vat.value;

        if (snoValue && vatValue) {
          stockUnitPatchUpdateInput.taxProfile = {
            sno: snoValue,
            vat: vatValue,
          };
        }
      }
    }

    const productPatchUpdateInput: ProductPatchUpdateInput = {
      id: this.product.id,
      sectionId: section.value!.id,
      name: name.value,
      type: type.value,
      stockUnit: stockUnitPatchUpdateInput,
    };

    if (unit.value === UNIT_TYPE.PIECE) {
      if (weight.value) {
        productPatchUpdateInput.weight = Number(weight.value);
      }
      productPatchUpdateInput.weightUnitType = weightUnit.value;
    }

    stockUnitPatchUpdateInput.nutritionFact = { calories: 0, protein: 0, fat: 0, carbohydrates: 0 };

    if (stockUnitPatchUpdateInput.nutritionFact) {
      stockUnitPatchUpdateInput.nutritionFact.protein = Number(proteins.value) || 0;
      stockUnitPatchUpdateInput.nutritionFact.fat = Number(fats.value) || 0;
      stockUnitPatchUpdateInput.nutritionFact.calories = Number(calories.value) || 0;
      stockUnitPatchUpdateInput.nutritionFact.carbohydrates = Number(carbons.value) || 0;
    }

    if (changeWorkshop.value) {
      productPatchUpdateInput.workshopId = workshop.value ? workshop.value.id : null;
    }

    if (techCard) {
      const { items, processOfCooking } = techCard.controls;

      const techCardItemAddInputs: TechCardItemAddInput[] = items.controls
        .filter((control) => !control.value.techCardItemId)
        .filter((control) => !!control.controls.stockUnit.value)
        .map((control) => {
          const itemControls = control.controls;
          const { stockUnit, netto } = itemControls;
          const bruttoUnit = itemControls.bruttoUnit.value;
          const baseBruttoUnit = itemControls.stockUnit.value?.data?.unit;
          const quantity = Number(itemControls.stockUnit.value?.data?.quantity);
          let brutto = Number(itemControls.brutto.value);

          if (baseBruttoUnit && bruttoUnit) {
            if (bruttoUnit !== baseBruttoUnit) {
              if (LITER_OR_KILOGRAM(bruttoUnit)) {
                brutto = brutto * MULTIPLIER;
              }

              if (MILLILITER_OR_GRAM(bruttoUnit)) {
                brutto = brutto / MULTIPLIER;
              }

              if (bruttoUnit === UNIT_TYPE.PIECE) {
                if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
                  brutto = brutto * quantity;
                }

                if (MILLILITER_OR_GRAM(baseBruttoUnit)) {
                  brutto = brutto * quantity;
                }
              }
            }
          }

          return {
            stockUnitId: stockUnit.value!.id,
            bruttoUnitType: bruttoUnit as UnitType,
            brutto: String(brutto),
            netto: netto.value,
          };
        });

      const techCardItemIds: string[] = items.controls.map((control) => control.value.techCardItemId!) || [];
      const prevTechCardItemIds: string[] =
        this.product?.stockUnits[0]?.techCard?.techCardItems?.map((techCardItem) => techCardItem.id!) || [];
      const diffTechCardItemIds = this.utilsService.getListsDiff(prevTechCardItemIds, techCardItemIds);

      const techCardPatchUpdateInput: TechCardPatchUpdateInput = {
        addItems: techCardItemAddInputs,
        processOfCooking: processOfCooking.value,
        removeItemIds: diffTechCardItemIds.removed,
      };

      productPatchUpdateInput.techCard = techCardPatchUpdateInput;
    }

    return productPatchUpdateInput;
  }

  deleteProduct(): void {
    if (!this.product?.id) {
      throw new Error('Product id is missing');
    }

    this.productStorage.deleteProduct({ id: this.product.id }).subscribe(
      () => {
        this.modalRef.close();
        if (!this.sidenavMode) {
          this.toList();
        }
        if (this.sidenavMode) {
          this.removeProductFromTable.next(this.product?.id!);
          this.tableSidebarService.closeTableSidenav();
        }
      },
      () => this.enableForm(),
    );
  }

  toList(product?: Product | ProductPatchUpdateInput | ProductCreateInput | null, shouldConfirm: boolean = false): void {
    this.formConfirmSaveService
      .closeForm(shouldConfirm)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          const listRoute = (product && product.type && PRODUCT_LIST_ROUTES[product.type]) || CatalogRoute.products;

          this.router.navigateByUrl(`${this.router.url.split('catalog')[0]}catalog/${listRoute}`);
        }
      });
  }

  back(): void {
    this.toList(this.product, true);
  }
  showExitModal() {
    this.formConfirmSaveService
      .closeForm(true)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.tableSidebarService.closeTableSidenav();
        }
      });
  }

  showModal(modalTemplate: TemplateRef<ModalBaseComponent>): void {
    this.modalRef = this.modalService.openDialog(modalTemplate);
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }

  initProductDialogForm(name: string, type?: ProductType): void {
    this.productDialogForm = this.fb.group<ProductDialogForm>({
      name: this.fb.nonNullable.control(name, [Validators.required, Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME)]),
      section: this.fb.control(null, [Validators.required]),
      type: this.fb.control(type ? type : null, [Validators.required]),
      weighable: this.fb.nonNullable.control(false),
      unit: this.fb.nonNullable.control(UNIT_TYPE.PIECE),
      quantity: this.fb.nonNullable.control('1', [Validators.required, Validators.min(1)]),
      primePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      salePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      surcharge: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
    });
  }

  private createProductDialog(): void {
    const { name, type, weighable, unit, quantity, section, primePrice, salePrice } = this.productDialogForm.controls;

    const stockUnitInput: StockUnitInput = {
      name: name.value,
      weighable: !weighable.value,
      unit: unit.value || 'NONE',
      quantity: quantity.value,
      primePrice: { amountValue: primePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
      salePrice: { amountValue: salePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
    };

    const productCreateInput: ProductCreateInput = {
      catalogId: this.catalogStorage.getCatalog().id,
      name: name.value,
      sectionId: section.value?.id,
      stockUnits: [stockUnitInput],
      type: type.value,
    };

    this.productStorage.createProductWithStockUnit({ product: productCreateInput }).subscribe((res) => {
      const stockUnit = res.data?.createProductV2?.output?.stockUnits[0];

      if (stockUnit && this.form.controls.techCard) {
        this.form.controls.techCard.controls.items
          .at(this.productDialogIndex)
          .controls.stockUnit.patchValue({ type: 'item', label: stockUnit.name, id: stockUnit.id, data: stockUnit });
      }
    });
  }

  openProductDialog(name: string, rows: Row<EntityWithId, ProductRowData>[], index: number): void {
    this.initProductDialogForm(name, PRODUCT_TYPE.INGREDIENT);
    this.productDialogRows = rows;
    this.productDialogIndex = index;
    this.modalRef = this.modalService.openDialog(CreateProductDialogComponent, {
      data: {
        form: this.productDialogForm,
        title: 'Новый ингредиент',
        name,
        close: (confirm: boolean) => this.closeProductDialog(confirm),
      },
    });
  }

  closeProductDialog(confirm: boolean): void {
    if (confirm) {
      if (this.productDialogForm.valid) {
        this.createProductDialog();
        this.hideModal();
      } else {
        this.validationErrorsService.markFormControls(this.productDialogForm);
      }
    } else {
      this.hideModal();
    }
  }

  getProducts(pageRequest: PageRequestInput, filter: ProductFilterInput): Observable<ProductPage> {
    return this.productStorage
      .getAllProductsPageable({
        pageRequest,
        filter,
      })
      .pipe(map((res) => res.data.allCatalogProductsPageable));
  }

  getProductsPromise(pageRequest: PageRequestInput, filter: ProductFilterInput): Promise<ProductPage> {
    return firstValueFrom(
      this.productStorage
        .getAllProductsPageable({
          pageRequest,
          filter,
        })
        .pipe(map((res) => res.data.allCatalogProductsPageable)),
    );
  }

  deleteProducts(variables: { id: string }[]): MutationBatchResult<'deleteProduct'> {
    return this.productStorage.deleteProducts(variables);
  }

  duplicateProduct(variables: MutationDuplicateProductArgs): MutationResult<'duplicateProduct'> {
    return this.productStorage.duplicateProduct(variables);
  }

  moveProductsToCategory(variables: MutationMoveProductArgs[]): MutationResult<'moveProduct'> {
    return this.productStorage.moveProductsToCategory(variables);
  }

  getProductsForSearch(
    pageRequest: PageRequestInput,
    searchText: string | undefined,
    excludeStockUnitIds: string[],
  ): QueryResult<'products'> {
    return this.productStorage.getProducts({
      pageRequest,
      filter: {
        search: searchText || '',
        excludeStockUnitIds,
      },
    });
  }
  getProductsBySectionIds(parentSectionIds: string[]): QueryResult<'products'> {
    return this.productStorage.getProductsShort({ filter: { parentSectionIds } });
  }

  getStockUnits(variables?: QueryStockUnitsArgs): QueryResult<'stockUnits'> {
    return this.productStorage.getStockUnits(variables);
  }

  updateProductType(variables: MutationUpdateProductV3Args): MutationResult<'updateProductV3'> {
    return this.productStorage.updateProduct(variables);
  }

  updateProductTypeBatch(variables: MutationUpdateProductV3Args[]): MutationResult<'updateProductV3'> {
    return this.productStorage.updateProductsBatch(variables);
  }

  getSumNetto() {
    let arrSum = 1;
    if (this.controls.techCard && this.controls.techCard.controls.items) {
      const items = this.controls.techCard.controls.items.value;
      if (items.length) {
        const arr: number[] = items!.map((group) => Number(group.netto));
        arrSum = arr.reduce((acc, item) => acc + item);
      }
    }

    const leavings = validateNumber(String(arrSum), MAX_INTEGER.NETTO, MAX_FRACTIONAL.NETTO);
    this.controls.leavings.setValue(leavings);
  }

  exportProducts(withIngredients: boolean) {
    const url = withIngredients
      ? `${environment.baseExportReportUrl}/${exportUrlByReportType.PRODUCTS_WITH_INGREDIENTS.url}`
      : `${environment.baseExportReportUrl}/${exportUrlByReportType.PRODUCTS_WITHOUT_INGREDIENTS.url}`;
    const fileName = withIngredients
      ? exportUrlByReportType.PRODUCTS_WITH_INGREDIENTS.fileName
      : exportUrlByReportType.PRODUCTS_WITHOUT_INGREDIENTS.fileName;

    this.exportReportService.exportReportFromUrlWithDefaultName(url, fileName);
  }
  changeSidenavMode(state: boolean) {
    this.sidenavMode = state;
  }
  setTabs(tabs: Tab[]) {
    this.tabs = tabs;
  }
  setCurrentTab(index: number) {
    this.currentTab.next(index);
  }
}
