import { FeatureEnableService } from '@app/shared/service/featureEnable.service';
import {
  ANALYTICS_CHILD_ITEMS,
  CATALOG_CHILD_ITEMS,
  EMPLOYEE_CHILD_ITEMS,
  MARKETING_CHILD_ITEMS,
  MARKETING_CHILD_ITEMS_WITH_BOOKING,
  SALES_CHILD_ITEMS,
  SETTINGS_CHILD_ITEMS,
  SETTINGS_CHILD_ITEMS_WITH_APPLICATION,
  SettingsRouteParam,
  WAREHOUSE_CHILD_ITEMS,
} from '@constants';
import { AccountStorage } from '@services/core';
import { RedirectService } from '@services/shared';
import { NavDrawerItem } from '@typings';

export const ROUTE_CREATE_NEW = 'new';
export const ROUTE_DATA_LAYOUT = 'withSidebar';
export const ROUTE_IMPORT = 'import';

export enum NavigationRoute {
  stores = 'stores',
  hr = 'hr',
  catalog = 'catalog',
  loyalty = 'loyalty',
  warehouse = 'warehouse',
}

export enum RootNavigationRoute {
  start = 'startingpage',
  analytics = 'analytics',
  stores = 'stores',
  catalog = 'catalog',
  marketing = 'marketing',
  warehouse = 'warehouse',
  settings = 'settings',
  profile = 'profile',
  bonuses = 'bonuses',
  employees = 'employees',
  sales = 'sales',
  application = 'application',
  addPositions = 'addPositions',
  addClient = 'addClient',
}

export enum RootNavigationRouteName {
  start = 'Старт',
  analytics = 'Аналитика',
  catalog = 'Каталог',
  sales = 'Продажи',
  marketing = 'Маркетинг',
  warehouse = 'Склад',
  settings = 'Настройки',
  application = 'Приложения ᵝ',
  profile = 'Профиль',
  bonuses = 'Мои бонусы',
  employees = 'Сотрудники',
  addPositions = 'Добавление позиций',
  addClient = 'Состав группы',
}

export const NAV_DRAWER_ITEMS = (
  feature: FeatureEnableService,
  accountStorage: AccountStorage,
  removeHidden: boolean = false,
  hideStart: boolean = false,
): NavDrawerItem[] => {
  let settingsItems: NavDrawerItem[] = feature.applications ? SETTINGS_CHILD_ITEMS_WITH_APPLICATION : SETTINGS_CHILD_ITEMS;
  let marketingItems: NavDrawerItem[] = feature.booking ? MARKETING_CHILD_ITEMS_WITH_BOOKING : MARKETING_CHILD_ITEMS;

  const settingsItem: NavDrawerItem = {
    title: RootNavigationRouteName.settings,
    route: RootNavigationRoute.settings,
    routes: settingsItems,
    icon: 'settings',
  };

  const profileItem: NavDrawerItem = {
    title: RootNavigationRouteName.profile,
    route: RootNavigationRoute.profile,
    hideInDrawer: true,
  };
  const addPositionItem: NavDrawerItem = {
    title: RootNavigationRouteName.addPositions,
    route: RootNavigationRoute.addPositions,
    icon: 'cancelFilter',
    hideInDrawer: true,
  };

  const addClientItem: NavDrawerItem = {
    title: RootNavigationRouteName.addClient,
    route: RootNavigationRoute.addClient,
    icon: 'cancelFilter',
    hideInDrawer: true,
  };

  if (accountStorage.getIsCacher()) {
    const bonusesItem: NavDrawerItem = {
      title: RootNavigationRouteName.bonuses,
      route: RootNavigationRoute.bonuses,
      icon: 'bonuses',
      onClick: function (orgId: string, redirectService: RedirectService) {
        redirectService.redirectToCashierPage(orgId);
      },
    };

    return [bonusesItem, profileItem];
  }

  let items: NavDrawerItem[] = [
    {
      title: RootNavigationRouteName.start,
      route: RootNavigationRoute.start,
      icon: 'flag',
      iconRight: 'close',
      hideInDrawer: hideStart,
      hideRoutes: true,
      onClick: function (orgId: string, redirectService: RedirectService) {
        redirectService.redirectToStart(orgId);
      },
      onRightClick: (accountStorage: AccountStorage, orgId: string, redirectService: RedirectService) => {
        accountStorage.hideStart$.next(true);
        localStorage.setItem('hideStart', 'true');
        redirectService.redirectToAnalytics(orgId);
      },
    },
    {
      title: RootNavigationRouteName.analytics,
      route: RootNavigationRoute.analytics,
      routes: ANALYTICS_CHILD_ITEMS,
      icon: 'analytic',
    },
    {
      title: RootNavigationRouteName.sales,
      route: RootNavigationRoute.sales,
      routes: SALES_CHILD_ITEMS,
      icon: 'store',
    },
    {
      title: RootNavigationRouteName.catalog,
      route: RootNavigationRoute.catalog,
      routes: CATALOG_CHILD_ITEMS,
      icon: 'catalog',
    },
    {
      title: RootNavigationRouteName.marketing,
      route: RootNavigationRoute.marketing,
      routes: marketingItems,
      icon: 'loyalty',
    },
    {
      title: RootNavigationRouteName.warehouse,
      route: RootNavigationRoute.warehouse,
      routes: WAREHOUSE_CHILD_ITEMS,
      icon: 'storage',
    },
    {
      title: RootNavigationRouteName.employees,
      route: RootNavigationRoute.employees,
      routes: EMPLOYEE_CHILD_ITEMS,
      icon: 'staff',
    },
    {
      title: RootNavigationRouteName.application,
      route: RootNavigationRoute.application,
      icon: 'puzzle',
      onClick: function (orgId: string, redirectService: RedirectService) {
        redirectService.redirectToApplication(orgId);
      },
      hideRoutes: true,
      routes: [
        {
          title: 'Яндекс',
          route: `${SettingsRouteParam.applicationId}/${SettingsRouteParam.yandex}`,
          routes: [
            {
              title: 'Яндекс',
              skipParent: true,
              route: 'info',
            },
            {
              title: 'Яндекс',
              skipParent: true,
              route: 'integrations',
            },
            {
              title: 'Подключение Яндекс.Еда',
              route: ROUTE_CREATE_NEW,
            },
            {
              title: 'Подключение Яндекс.Еда',
              route: SettingsRouteParam.confId,
            },
          ],
        },
        {
          title: 'DocsInBox',
          route: `${SettingsRouteParam.applicationId}/${SettingsRouteParam.docsInBox}`,
          routes: [
            {
              title: 'DocsInBox',
              skipParent: true,
              route: 'info',
            },
            {
              title: 'DocsInBox',
              skipParent: true,
              route: 'integrations',
            },
            {
              title: 'Подключение DocsInBox',
              route: ROUTE_CREATE_NEW,
            },
            {
              title: 'Подключение DocsInBox',
              route: SettingsRouteParam.confId,
            },
          ],
        },
        {
          title: 'Телеграм консьерж-бот',
          route: `${SettingsRouteParam.applicationId}/${SettingsRouteParam.clientBot}`,
          routes: [
            {
              title: 'Телеграм консьерж-бот',
              skipParent: true,
              route: 'info',
            },
            {
              title: 'Телеграм консьерж-бот',
              skipParent: true,
              route: 'integrations',
            },
          ],
        },
        {
          title: 'Telegram бот для бизнеса',
          route: `${SettingsRouteParam.applicationId}/${SettingsRouteParam.businessBot}`,
          routes: [
            {
              title: 'Telegram бот для бизнеса',
              skipParent: true,
              route: 'info',
            },
            {
              title: 'Telegram бот для бизнеса',
              skipParent: true,
              route: 'integrations',
            },
            {
              title: 'Подключение Telegram бота для бизнеса',
              route: ROUTE_CREATE_NEW,
            },
            {
              title: 'Подключение Telegram бота для бизнеса',
              route: SettingsRouteParam.confId,
            },
          ],
        },
      ],
    },
    settingsItem,
    profileItem,
    addPositionItem,
    addClientItem,
  ];

  if (removeHidden) {
    items = removeHiddenItems(items);
  }

  addParentLinks(items);

  return items;
};

export function addParentLinks(items: NavDrawerItem[], parentLink?: NavDrawerItem): void {
  items.forEach((item) => {
    item.parent = parentLink;

    if (item.routes) {
      addParentLinks(item.routes, item);
    }
  });
}

export function removeHiddenItems(items: NavDrawerItem[]): NavDrawerItem[] {
  return items
    .filter((item) => !item.hideInDrawer)
    .map((item) => ({
      ...item,
      routes: item.routes ? removeHiddenItems(item.routes) : undefined,
    }));
}
