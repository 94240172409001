import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CheckLayoutComponent } from '@app/layout/check-layout/check-layout.component';
import { ScopeNavigationRoute } from '@app/typings/role';
import {
  AuthRoute,
  RootNavigationRoute,
  ROUTE_CREATE_NEW,
  ROUTE_DATA_LAYOUT,
  SettingsRoute,
  SettingsRouteParam,
  SettingsSubscriptionsRoute,
} from '@constants';
import { AccessGuard, AccountGuard, AuthGuard, InvitationGuard, OrganizationGuard } from '@guards';
import { BaseLayoutComponent, SidenavLayoutComponent } from '@layout';
import { BillOrderResolver, InvoiceResolver, LicenceOrderResolver, ServicePlanOrderResolver } from '@resolvers/settings';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: SidenavLayoutComponent,
    canActivate: [AuthGuard, AccountGuard],
  },
  {
    path: AuthRoute.auth,
    component: BaseLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    data: { noHeader: false, showLogo: false, showFooterInfo: false, showHeader: true },
  },
  {
    path: AuthRoute.acceptInvitation,
    component: BaseLayoutComponent,
    loadChildren: () => import('./modules/auth/page/invite/invite.module').then((m) => m.InviteModule),
    canActivate: [InvitationGuard, AuthGuard],
  },
  {
    path: ':id',
    canActivate: [AuthGuard, AccountGuard, OrganizationGuard],
    data: { layout: ROUTE_DATA_LAYOUT },
    children: [
      {
        path: RootNavigationRoute.start,
        component: SidenavLayoutComponent,
        loadChildren: () => import('@app/modules/start/start.module').then((m) => m.StartModule),
      },
      {
        path: 'setup',
        pathMatch: 'full',
        loadChildren: () =>
          import('@app/modules/auth/page/create-organization/create-organization.module').then((m) => m.CreateOrganizationModule),
        data: { noHeader: true, showLogo: true },
      },
      {
        path: 'payment-required',
        component: BaseLayoutComponent,
        loadChildren: () => import('@app/modules/payment-required/payment-required.module').then((m) => m.PaymentRequiredModule),
        data: { noHeader: true },
      },
      {
        path: RootNavigationRoute.analytics,
        component: SidenavLayoutComponent,
        canActivate: [AccessGuard],
        data: { module: ScopeNavigationRoute.analytics },
        loadChildren: () => import('@app/modules/analytics/analytics.module').then((m) => m.AnalyticsModule),
      },
      {
        path: RootNavigationRoute.sales,
        component: SidenavLayoutComponent,
        canActivate: [AccessGuard],
        data: { module: ScopeNavigationRoute.sales },
        loadChildren: () => import('@app/modules/sales/sales.module').then((m) => m.SalesModule),
      },
      {
        path: RootNavigationRoute.catalog,
        component: SidenavLayoutComponent,
        canActivate: [AccessGuard],
        data: { module: ScopeNavigationRoute.catalog },
        loadChildren: () => import('@app/modules/catalog/catalog.module').then((m) => m.NewCatalogModule),
      },
      {
        path: RootNavigationRoute.marketing,
        component: SidenavLayoutComponent,
        canActivate: [AccessGuard],
        data: { module: ScopeNavigationRoute.marketing },
        loadChildren: () => import('@app/modules/marketing/marketing.module').then((m) => m.MarketingModule),
      },
      {
        path: RootNavigationRoute.warehouse,
        component: SidenavLayoutComponent,
        canActivate: [AccessGuard],
        data: { module: ScopeNavigationRoute.warehouse },
        loadChildren: () => import('@app/modules/warehouse/warehouse.module').then((m) => m.NewWarehouseModule),
      },
      {
        path: RootNavigationRoute.employees,
        component: SidenavLayoutComponent,
        canActivate: [AccessGuard],
        data: { module: ScopeNavigationRoute.employees },
        loadChildren: () => import('@app/modules/employees/employees.module').then((m) => m.EmployeesModule),
      },
      {
        path: RootNavigationRoute.application,
        component: SidenavLayoutComponent,
        canActivate: [AccessGuard],
        data: { module: ScopeNavigationRoute.application },
        loadChildren: () => import('@app/modules/application-shop/application-shop.module').then((m) => m.ApplicationShopModule),
      },
      {
        path: RootNavigationRoute.settings,
        component: SidenavLayoutComponent,
        canActivate: [AccessGuard],
        data: { module: ScopeNavigationRoute.settings },
        loadChildren: () => import('@app/modules/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${SettingsSubscriptionsRoute.invoices}/:${SettingsRouteParam.invoiceId}`,
        component: CheckLayoutComponent,
        data: { param: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${SettingsSubscriptionsRoute.invoices}` },
        loadChildren: () =>
          import('@app/modules/settings/pages/subscriptions/invoices/invoice/invoice.module').then((m) => m.InvoiceModule),
        resolve: {
          invoice: InvoiceResolver,
        },
      },
      {
        path: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${SettingsSubscriptionsRoute.invoices}/:${SettingsRouteParam.invoiceId}/${SettingsRoute.order}`,
        component: CheckLayoutComponent,
        data: { param: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${SettingsSubscriptionsRoute.invoices}` },
        loadChildren: () =>
          import('@app/modules/settings/pages/subscriptions/invoices/invoice/invoice.module').then((m) => m.InvoiceModule),
        resolve: {
          order: BillOrderResolver,
        },
      },
      {
        path: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${ROUTE_CREATE_NEW}`,
        component: CheckLayoutComponent,
        data: { title: 'Добавление лицензий', param: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}` },
        loadChildren: () =>
          import('@app/modules/settings/pages/subscriptions/invoices/licence/licence.module').then((m) => m.LicenceModule),
      },
      {
        path: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${ROUTE_CREATE_NEW}/${SettingsRoute.order}`,
        component: CheckLayoutComponent,
        data: { param: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${SettingsSubscriptionsRoute.invoices}` },
        loadChildren: () =>
          import('@app/modules/settings/pages/subscriptions/invoices/invoice/invoice.module').then((m) => m.InvoiceModule),
        resolve: {
          order: LicenceOrderResolver,
        },
      },
      {
        path: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${SettingsRoute.subscriptionChange}`,
        component: CheckLayoutComponent,
        data: { title: 'Изменение подписки', param: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}` },
        loadChildren: () =>
          import('@app/modules/settings/pages/subscriptions/subscription-change/subscription-change.module').then(
            (m) => m.SubscriptionChangeModule,
          ),
      },
      {
        path: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${SettingsRoute.subscriptionChange}/:${SettingsRouteParam.servicePlanId}`,
        component: CheckLayoutComponent,
        data: { param: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${SettingsRoute.subscriptionChange}` },
        loadChildren: () =>
          import('@app/modules/settings/pages/subscriptions/invoices/invoice/invoice.module').then((m) => m.InvoiceModule),
        resolve: {
          order: ServicePlanOrderResolver,
        },
      },
      {
        path: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/payment-result/:${SettingsRouteParam.orderId}`,
        component: CheckLayoutComponent,
        data: {
          param: `${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${SettingsSubscriptionsRoute.info}`,
        },
        loadChildren: () =>
          import('@app/modules/settings/pages/subscriptions/payment-result/payment-result.module').then((m) => m.PaymentResultModule),
      },
      {
        path: RootNavigationRoute.profile,
        component: SidenavLayoutComponent,
        canActivate: [AccessGuard],
        data: { module: ScopeNavigationRoute.profile },
        loadChildren: () => import('@app/modules/profile/profile.module').then((m) => m.NewProfileModule),
      },
      {
        path: RootNavigationRoute.bonuses,
        component: SidenavLayoutComponent,
        loadChildren: () => import('@app/modules/bonuses/pages/bonuses/bonuses.module').then((m) => m.BonusesModule),
      },
    ],
  },
  {
    path: '**',
    loadChildren: () => import('@app/modules/error/error.module').then((m) => m.ErrorModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
